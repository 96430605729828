import React, {useEffect, useState} from 'react';

import Button from 'components/Button';
import { monetaryFormatter } from 'libs/monetary';
import { modalActions } from 'store/modal/modal.store';
import { useSelector, useDispatch } from 'react-redux';
import { cartActions } from 'modules/cart/services/cart.store';
import BillingRequestForm from 'modules/cart/UI/BillingRequestForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";

const CartItem = ({benefit}: any) => {

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const [count, setCount] = useState<any>(1);

  useEffect(() => {
    setCount(benefit.count);
  }, []);

  useEffect(() => {
    if (count === 0) setCount(1);
    countHandler(benefit.id, count);
  }, [count]);

  const countHandler = (benefitId: number, count: any): void => {
    console.log(count, 'count');
    if (+count === 0) return;
    dispatch(cartActions.setCount({benefitId: benefitId, count: count}))
  };

  const removeFromCart = (benefitId: number): void => {
    dispatch(cartActions.removeFromCart(benefitId));
  };

  return (
    <li key={benefit.id} className="flex items-center justify-between py-4">
      <div className="lg:flex items-center gap-x-5">
        <div className="flex-shrink-0">
          {benefit.pictures[0] &&
              <img
                  className="inline-block h-16 w-32"
                  src={benefit.pictures[0].image}
                  alt="cover"
              />
          }
          {!benefit.pictures[0] &&
              <img
                  className="inline-block h-16 w-32 transform scale-75"
                  src="assets/hob-logo.png"
                  alt="cover"
              />
          }
        </div>
        <div>
          <div className="flex justify-between mt-2 lg:mt-0">
            <h3 className="text-sm">
              <a href={benefit.href} className="font-medium text-gray-700 hover:text-gray-800">
                {benefit.name}
              </a>
            </h3>
          </div>
          <p className="mt-1 text-sm font-bold text-gray-900">
            {monetaryFormatter(benefit.price * (100 - benefit.discount) / 100)} {user.currency}
          </p>
        </div>
      </div>

      <div className="lg:flex items-end gap-x-2 space-y-4 lg:space-y-0 pl-2 lg:pl-8">
        <div className="flex items-center gap-x-1">
          {/*<button onClick={() => countHandler(benefit.id, count)}*/}
          {/*        className="w-8 h-8 border border-base-600 text-base-600 rounded hover:bg-base-600 hover:text-white transition duration-200 ease-in-out">*/}
            {/*<font-awesome-icon icon="fa-solid fa-check" class="" />*/}
          {/*</button>*/}
          <div
            className="flex items-center justify-around gap-x-1 text-base-600 font-medium h-8 w-28 rounded border border-base-600 hover:bg-white ml-auto lg:ml-0">
            <button className="w-1/3 h-8 rounded-l text-xl hover:bg-base-600 hover:text-white"
                    onClick={() => setCount(benefit.count - 1)}>-
            </button>
            <div className="w-1/3 h-8 flex items-center justify-center bg-transparent">
              {/*<p>{benefit.count}</p>*/}
              <input value={count} type="number" onBlur={(e: any) => count < 1 ? setCount(1) : null} onChange={(e: any) => setCount(e.target.value)}
                     className="bg-transparent w-full text-center border-none outline-none h-6 p-0 m-0"/>
            </div>
            <button className="w-1/3 h-8 rounded-r text-xl hover:bg-base-600 hover:text-white"
                    onClick={() => setCount(benefit.count + 1)}>+
            </button>
          </div>
        </div>
        <div className="text-right space-y-2 lg:space-y-0 w-32">
          {benefit.discount > 0 &&
              <p className="font-medium line-through text-sm">{monetaryFormatter(benefit.price * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
          {benefit.discount > 0 &&
              <p className="font-medium">{monetaryFormatter(benefit.price * (100 - benefit.discount) / 100 * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
          {!benefit.discount &&
              <p className="font-medium">{monetaryFormatter(benefit.price * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
          <button onClick={() => removeFromCart(benefit.id)}
                  className="text-sm text-red-600 tracking-wide hover:underline">Ukloni benefit
          </button>
        </div>
      </div>
    </li>
  )
}

const Cart = () => {

  const dispatch: any = useDispatch();
  const cart: any = useSelector((state: any) => state.cartStore.cart);
  const user: any = useSelector((state: any) => state.authStore.user);

  const getTotalPrice = () => {
    return cart.reduce((partialSum: any, benefit: any) => partialSum + Number(benefit.price) * (100 - benefit.discount) / 100 * benefit.count, 0);
  };

  const openAddBenefitForm = (): void => {
    let modalContent: React.JSX.Element = (
      <BillingRequestForm/>
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Zahtev za naplatu',
          size: 'small',
        },
      })
    );
  };

  return (
    <div
      className={`collapsible-content ${cart.length > 0 ? 'open' : 'h-0'} grid grid-cols-1 lg:grid-cols-3 items-start gap-6`}>
      <section className="lg:col-span-2">
        <div className="bg-gray-50 rounded-lg h-28 flex items-center p-5 shadow">
          <div className="text-sm text-gray-500 font-medium">
            <p className="text-xl font-semibold text-gray-900">Korpa <sup>({cart.length})</sup></p>
            <p className="text-sm mt-5">Ovde se nalaze benefiti koje želiš da naplatiš</p>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg p-5 mt-6 shadow-lg">
          {cart.length > 0 && <h3 className="font-bold mb-4 text-gray-500">Benefiti</h3>}
          {cart.length === 0 && <h3 className="font-bold py-2 text-gray-500">Vaša korpa je prazna!</h3>}
          {cart.length > 0 &&
              <ul className="border-t border-gray-200 divide-y divide-gray-200 max-h-80 overflow-y-auto pr-3">
                {cart.map((benefit: any) =>
                  <CartItem benefit={benefit} />
                  // <li key={benefit.id} className="flex items-center justify-between py-4">
                  //   <div className="lg:flex items-center gap-x-5">
                  //     <div className="flex-shrink-0">
                  //       {benefit.pictures[0] &&
                  //         <img
                  //           className="inline-block h-16 w-32"
                  //           src={benefit.pictures[0].image}
                  //           alt="cover"
                  //         />
                  //       }
                  //       {!benefit.pictures[0] &&
                  //         <img
                  //           className="inline-block h-16 w-32 transform scale-75"
                  //           src="assets/hob-logo.png"
                  //           alt="cover"
                  //         />
                  //       }
                  //     </div>
                  //     <div>
                  //       <div className="flex justify-between mt-2 lg:mt-0">
                  //         <h3 className="text-sm">
                  //           <a href={benefit.href} className="font-medium text-gray-700 hover:text-gray-800">
                  //             {benefit.name}
                  //           </a>
                  //         </h3>
                  //       </div>
                  //       <p className="mt-1 text-sm font-bold text-gray-900">
                  //         {monetaryFormatter(benefit.price * (100 - benefit.discount) / 100)} {user.currency}
                  //       </p>
                  //     </div>
                  //   </div>
                  //
                  //   <div className="lg:flex items-end gap-x-2 space-y-4 lg:space-y-0 pl-2 lg:pl-8">
                  //     <div className="flex items-center gap-x-1">
                  //       <button onClick={() => countHandler(benefit.id, count)} className="w-8 h-8 border border-base-600 text-base-600 rounded hover:bg-base-600 hover:text-white transition duration-200 ease-in-out">
                //         {/*<font-awesome-icon icon="fa-solid fa-check" class="" />*/}
                //       </button>
                //       <div className="flex items-center justify-around gap-x-1 text-base-600 font-medium h-8 w-28 rounded border border-base-600 hover:bg-white ml-auto lg:ml-0">
                //         <button className="w-1/3 h-8 rounded-l text-xl hover:bg-base-600 hover:text-white" onClick={() => countHandler(benefit.id, benefit.count - 1)}>-</button>
                //         <div className="w-1/3 h-8 flex items-center justify-center bg-transparent">
                //           {/*<p>{benefit.count}</p>*/}
                //           <input value={benefit.count} type="number" onChange={(e: any) => setCount(e.target.value)} className="bg-transparent w-full text-center border-none outline-none h-6 p-0 m-0" />
                //           {/*<InputHandler id={benefit.id} count={benefit.count} />*/}
                //         </div>
                //         <button className="w-1/3 h-8 rounded-r text-xl hover:bg-base-600 hover:text-white" onClick={() => countHandler(benefit.id, benefit.count + 1)}>+</button>
                //       </div>
                //     </div>
                //     <div className="text-right space-y-2 lg:space-y-0 w-32">
                //       {benefit.discount > 0 && <p className="font-medium line-through text-sm">{monetaryFormatter(benefit.price * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
                //       {benefit.discount > 0 && <p className="font-medium">{monetaryFormatter(benefit.price * (100 - benefit.discount) / 100 * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
                //       {!benefit.discount && <p className="font-medium">{monetaryFormatter(benefit.price * (benefit.count ? benefit.count : 1))} {user.currency}</p>}
                //       <button onClick={() => removeFromCart(benefit.id)} className="text-sm text-red-600 tracking-wide hover:underline">Ukloni benefit</button>
                //     </div>
                //   </div>
                // </li>
              )}
            </ul>
          }
        </div>
      </section>
      <section className="lg:col-span-1 bg-gray-50 p-5 lg:p-8 rounded-lg shadow space-y-4">
        <h2 className="text-lg font-medium text-gray-900">Pregled kupovine</h2>
        <div className="flex items-center justify-between border-y py-4 text-sm font-medium">
          <p className="text-gray-500">Broj benefita</p>
          <p>{ cart.length }</p>
        </div>
        <div className="pt-4 flex items-start justify-between">
          <div className="leading-3">
            <dt className="text-base font-medium text-gray-900 pb-0 mb-0">Ukupna cena:</dt>
            <small className="text-xs">(PDV je uračunat u cenu)</small>
          </div>
          <dd className="text-base font-black text-gray-900">{ monetaryFormatter(getTotalPrice()) } {user.currency}</dd>
        </div>
        <div>
          <Button
            bg="primary"
            className="w-full mt-3"
            size="regular"
            onClick={openAddBenefitForm}
          >
            <FontAwesomeIcon icon={faCashRegister} className="mr-2" />
            Naplata
          </Button>
        </div>
      </section>
    </div>
  )
};

export default Cart;