import React, { useState } from 'react';


import { useDispatch } from 'react-redux';
import {
  faChevronDown,
  faArrowRightArrowLeft,
  faArrowRightFromBracket, faCartArrowDown, faIcons,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from 'modules/auth/services/auth.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, Switch } from '@headlessui/react';


const SubHeader = () => {

  const location: any = useLocation();
  const navigate: any = useNavigate();

  return (
    <div className="w-full text-white flex items-center cursor-pointer font-medium text-center">
      <div onClick={() => navigate('/')} className={`w-1/3 flex items-center justify-center gap-x-2 bg-base-600 shadow rounded-l-lg h-[45px] hover:bg-base-500 animation duration-200 ease-in-out ${location.pathname === '/' && 'border-b-4 border-white'}`}>
        <FontAwesomeIcon icon={faCartArrowDown} className="text-2xl w-6"/>
        <p>In-Store kupovina</p>
      </div>
      <div onClick={() => navigate('/transactions')} className={`w-1/3 flex items-center justify-center h-[45px] gap-x-2 bg-base-600 hover:bg-base-500 animation duration-200 ease-in-out ${location.pathname === '/transactions' && 'border-b-4 border-white'}`}>
        <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-2xl w-6"/>
        <p>Lista kupovina</p>
      </div>
      <div onClick={() => navigate('/benefits')} className={`w-1/3 flex items-center justify-center gap-x-2 bg-base-600 shadow  rounded-r-lg h-[45px] hover:bg-base-500 animation duration-200 ease-in-out ${location.pathname === '/benefits' && 'border-b-4 border-white'}`}>
        <FontAwesomeIcon icon={faIcons} className="text-2xl w-6"/>
        <p>Benefiti</p>
      </div>
    </div>
  )
};

const Header = () => {

  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const user = useSelector((state: any) => state.authStore.user);
  const isOpenSidebar: boolean = useSelector((state: any) => state.sidebarStore.isOpen);
  const [isUser, setIsUser] = useState(false);

  const logOutHandler = (): void => {
    dispatch(authActions.logOut());
  }

  const getNameInitials = (firstName: string, lastName: string): any => {
    if (firstName && lastName) return firstName.slice(0, 1) + lastName.slice(0, 1);
    if (firstName && !lastName) return firstName.slice(0, 1);
    if (!firstName && lastName) return lastName.slice(0, 1);
    if (!firstName && !lastName) return '';
  }

  const goToAnalytics = (): void => {
    navigate('/');
  }


  return (
    <header className="sticky top-0 bg-primary-50 pt-1 z-40">
      <div onClick={goToAnalytics}>
        <img onClick={goToAnalytics} src="assets/logo2.png" alt="logo" className={`fixed top-8 lg:top-7 z-30 w-24 h-12 xl:w-28 xl:h-14 ml-2 md:ml-3.5 lg:ml-3.5 xl:ml-5 cursor-pointer animation duration-300 ease-in-out ${isOpenSidebar ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`} />
        <img onClick={goToAnalytics} src="assets/logo1.png" alt="logo" className={`fixed top-8 lg:top-7 z-30 w-12 h-12 md:w-12 md:h-12 ml-4 md:ml-9 xl:ml-11 cursor-pointer animation duration-300 ease-in-out ${!isOpenSidebar ? 'transform translate-x-0 opacity-100' : 'transform -translate-x-40 opacity-0'}`} />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="relative h-[72px] bg-gradient-to-r from-base-500 to-base-600 mt-4 ml-[130px] lg:ml-[113px] mr-5 lg:mr-[40px] rounded-xl">
          <div className="h-full px-6 pt-4 flex items-start justify-end">
            <div className="flex items-start gap-x-3">
              <p className="text-white font-bold">{user.firstName} {user.lastName}</p>
              <Menu as="div">
                <MenuButton className="rounded-full focus:outline-none z-20 relative">
                  {user.company.picture &&
                      <div>
                          <img className="h-[36px] w-[36px] rounded-full border-2 border-white"
                               src={user.company.picture} alt="profile pic"/>
                          <FontAwesomeIcon icon={faChevronDown}
                                           className="text-xs text-white transform -translate-y-2"/>
                      </div>
                  }
                  {!user.company.picture &&
                      <>
                          <div
                              className="bg-gray-400 text-gray-50 overflow-hidden w-[34px] w-[34px] border border-white rounded-full flex items-center justify-center uppercase tracking-wide">
                            {getNameInitials(user.firstName, user.lastName)}
                          </div>
                          <FontAwesomeIcon icon={faChevronDown}
                                           className="text-xs text-white transform -translate-y-2"/>
                      </>
                  }
                </MenuButton>
                <MenuItems className="z-10 font-normal origin-top-right absolute top-12 right-8 mt-4 w-64 rounded-md py-1 bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem>
                    <div className="h-16 flex px-4 py-2 gap-x-3">
                      <div>
                        {user.company.picture &&
                          <div>
                            <img className="h-12 w-12 rounded-full border-2 border-white" src={user.company.picture} alt="profile pic"/>
                            <FontAwesomeIcon icon={faChevronDown} className="text-xs text-white transform -translate-y-2"/>
                          </div>
                        }
                        {!user.company.picture &&
                          <>
                            <div className="bg-gray-400 text-gray-50 overflow-hidden w-12 h-12 border border-white rounded-full flex items-center justify-center uppercase tracking-wide">
                              {getNameInitials(user.firstName, user.lastName)}
                            </div>
                            <FontAwesomeIcon icon={faChevronDown} className="text-xs text-white transform -translate-y-2"/>
                          </>
                        }
                      </div>
                      <div className="text-sm text-gray-600 pt-1">
                        {user.company.name}
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="pb-2 pt-1 flex justify-center">
                      <div onClick={() => null} className=" w-5/6 text-base-500 inline-flex items-center px-2.5 py-1 border border-base-500 shadow-sm rounded-xl bg-transparent hover:bg-base-600 hover:text-white text-center justify-center">
                        Pogledaj profil
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div className="cursor-pointer border-t border-gray-200 pt-2">
                      <p className="pl-4 font-bold text-black mt-2">Podrška</p>
                    </div>
                  </MenuItem>

                  <MenuItem>
                    <a href="assets/tutorijal-2024.pdf" target="_blank" className="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                      Tutorijal
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <div className="border-t">
                      <p onClick={logOutHandler} className="flex items-center cursor-pointer px-4 py-2 text-gray-700 hover:bg-base-600 hover:text-white">
                        <span>Izloguj se</span>
                        <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2"/>
                      </p>
                    </div>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
          <div className="w-full absolute -bottom-5 left-0">
            <div className="w-10/12 mx-auto h-[45px] bg-base-600 rounded-lg flex items-center">
              <SubHeader />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;