import React, {useEffect, useState} from 'react';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface BenefitDocumentInputProps {
  promo: any;
  onDelete: () => void;
}

const BenefitDocumentInput = ({ promo, onDelete }: BenefitDocumentInputProps) => {

  const [title, setTitle] = useState('promo');

  useEffect(() => {
    if (promo) setTitle(promo.title);
  }, [promo]);

  return (
    <div>
      <a target="_blank" href={promo.file} className="group hover:text-base-600 truncate">
        <FontAwesomeIcon icon={faFile} className="group-hover:text-base-600 text-2xl text-gray-500"/>
        <span className="pl-2">{promo.title}</span>
      </a>
      <div className="flex items-center gap-x-1.5">
        <div className="mt-1">
          <input value={title} type="text" className="h-10 px-2 max-w-lg w-28 shadow-sm border focus:ring focus:ring-base-500 focus:outline-none sm:max-w-xs sm:text-sm border-gray-300 rounded-md"/>
        </div>
        <div className="flex items-center">
          <Button
            bg="outline-warning"
            className="w-10 mt-0.5 flex items-center justify-center gap-x-2"
            size="regular"
            onClick={onDelete}
          >
            <FontAwesomeIcon icon={faTrashCan}/>
          </Button>
        </div>
      </div>
    </div>
)
  ;
};

export default BenefitDocumentInput;