import React, {useState} from 'react';

const useFilters = () => {
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [searchString, setSearchString] = useState<any>('');
  const [status, setStatus] = useState<any>('');

  return {
    startDate,
    endDate,
    searchString,
    status,
    setStartDate,
    setEndDate,
    setSearchString,
    setStatus
  };
};

export default useFilters;