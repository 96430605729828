import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: ['#e00000', '#FFFFFF', '#000000'] }, { background: ['#e00000'] }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'color',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

interface TextEditorProps {
  label?: string;
  value?: any;
  onChange: (payload: any) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({ label, value, onChange }) => {
  const [localValue, setLocalValue] = useState('');
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  const onChangeHandler = (content: string) => {
    setLocalValue(content);
    onChange(content);
  };

  return (
    <div className="h-60">
      {label && (
        <label className="block text-gray-700 text-sm font-bold mb-1">{label}</label>
      )}
      <ReactQuill
        theme="snow"
        className="h-44"
        value={localValue}
        defaultValue={value || ''}
        modules={modules}
        formats={formats}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default TextEditor;
