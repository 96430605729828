import React from 'react';
import { useSelector } from 'react-redux';
import { CirclesWithBar } from 'react-loader-spinner';

interface LoaderProps {
  classNames?: string;
  color?: string;
  size?: string;
}

const colors = {
  white: '#ffffff',
  primary: '#046c9c',
}

const sizes = {
  small: '25',
  regular: '80',
  large: '100',
}

const ElementLoader = ({ classNames, color, size }: LoaderProps) => {

  return (
      <div className={`${classNames} absolute top-0 left-0 h-full w-full z-[50] backdrop-blur-[2px] animation duration-200 ease-in-out`}>
        <div className={`${classNames} absolute top-0 left-0 h-full w-full z-[50] backdrop-blur-[2px] animation duration-200 ease-in-out`}>
          <svg className="pulse-animation" viewBox="0 0 16 16" width="30px" height="16px" version="1.1"
               xmlns="http://www.w3.org/2000/svg">
            <circle id="c1" cx="8" cy={8} r="8" fill="#29B6F6"/>
            <circle id="c2" cx="14" cy={8} r="8" fill="#1e4154"/>
          </svg>
        </div>
        {/*<CirclesWithBar*/}
        {/*  // @ts-ignore*/}
        {/*  height={sizes[size] || sizes.regular}*/}
        {/*  // @ts-ignore*/}
        {/*  width={sizes[size] || sizes.regular}*/}
        {/*  // @ts-ignore*/}
        {/*  color={colors[color] || colors.primary}*/}
        {/*  ariaLabel="infinity-spin-loading"*/}
        {/*  wrapperStyle={{}}*/}
        {/*  wrapperClass="z-50"*/}
        {/*/>*/}
      </div>
  );
};

export default ElementLoader;