import http from 'libs/http';


class AuthApi {
  login = (payload: any) => {
    return http.post('/user/token/', payload);
  };

  getUser = () => {
    return http.get('/v2/me/');
  };
}


export default AuthApi;