import React, {useEffect} from 'react';
import { Tooltip } from 'react-tooltip';
import {useSelector} from "react-redux";
import {monetaryFormatter} from 'libs/monetary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { ElementLoader } from 'components/Loaders';


interface GridViewProps {
  benefits: any[];
  onAddToCart: (benefit: any) => void;
  onRemoveFromCart: (benefitId: any) => void;
}

const GridView = ({ benefits, onAddToCart, onRemoveFromCart }: GridViewProps) => {

  const user: any = useSelector((state: any) => state.authStore.user);
  const cart: any = useSelector((state: any) => state.cartStore.cart);
  const loading: any = useSelector((state: any) => state.loaderStore.loading);

  const checkIsInCart = (id: number) => {
    const isInCart = cart.find((benefit: any) => benefit.id === id);
    return !!isInCart;
  }


  return (
    <ul role="list" className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 my-9`}>
      {benefits.map((benefit: any) => (
        <li key={benefit.id} className="group h-56 md:h-72 w-full mx-auto col-span-1 rounded-xl bg-white shadow transform hover:scale-[1.03] animation duration-200 ease-in-out overflow-hidden">
          <div className="w-full h-3/6 flex items-center justify-center">
            {benefit.pictures[0] && <img src={benefit.pictures[0].image} className="w-full h-full object-center" alt="cover"/>}
            {!benefit.pictures[0] && <img src="assets/hob-logo.png" className="h-4/6 lg:h-3/6 object-center" alt="hob" />}
          </div>
          <div className="w-full h-1/6 bg-base-200 px-2.5 flex items-center justify-between">
            <p className="font-black group-hover:text-base-500 animation duration-200 ease-in-out">{ monetaryFormatter(+benefit.price) } { benefit.currency }</p>
            <div>
              {!checkIsInCart(benefit.id) &&
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className={`text-gray-500 hover:text-base-500 text-2xl cursor-pointer transform hover:scale-110 animation duration-200 ease-in-out`}
                  onClick={() => onAddToCart(benefit)}
                />
              }
              {checkIsInCart(benefit.id) &&
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className={`text-base-500 text-2xl cursor-pointer transform hover:scale-110 animation duration-200 ease-in-out`}
                  onClick={() => onRemoveFromCart(benefit.id)}
                />
              }
            </div>
          </div>
          <div className="w-full h-2/6 p-2.5 font-medium group-hover:text-base-500 animation duration-200 ease-in-out">
            { benefit.name }
          </div>
        </li>
      ))}
    </ul>
  )
};

export default GridView;
