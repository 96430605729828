import { createSlice } from '@reduxjs/toolkit';

import InStoreShoppingApi from './inStoreShopping.api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import {setHeaders} from "libs/http";

const api: InStoreShoppingApi = new InStoreShoppingApi();

interface inStoreShoppingState {
  benefits: any[];
  nextPage: number | null;
  searchString: string;
  sortBy: string;
  ordering: string;
  count: string | number;
  isNfc: boolean;
}

const initialState: inStoreShoppingState = {
  benefits: [],
  nextPage: null,
  searchString: '',
  sortBy: 'added',
  ordering: '-',
  count: -1,
  isNfc: true,
};

const inStoreShoppingSlice: any = createSlice({
  name: 'inStoreShopping',
  initialState,
  reducers: {

    setBenefits: (state: any, action: PayloadAction<any, any>): any => {
      const rawBenefits: any[] = [...state.benefits, ...action.payload];

      const uniqueBenefitsMap: Map<any, any> = new Map();

      rawBenefits.forEach(benefit => {
        if (benefit && benefit.id) {
          uniqueBenefitsMap.set(benefit.id, {...benefit, count: 1});
        }
      });

      state.benefits = Array.from(uniqueBenefitsMap.values());
    },
    setIsNfc: (state: any, action: PayloadAction<any, any>): any => {
      state.isNfc = action.payload;
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.searchString = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.ordering = action.payload;
    },
    setSort: (state: any, action: PayloadAction<string, any>): any => {
      state.sortBy = action.payload;
    },
    resetBenefits: (state: any): any => {
      state.benefits = [];
    },
  },
});


export const getBenefits = (isFetchMore: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage: string = getState().inStoreShoppingStore.nextPage;
      const searchString: string = getState().inStoreShoppingStore.searchString;
      const sortBy = await getState().inStoreShoppingStore.sortBy;
      const ordering = await getState().inStoreShoppingStore.ordering;
      const isNfc: boolean = await getState().inStoreShoppingStore.isNfc;

      let url: string = `/v2/admin/provider-benefits/?is_nfc=${isNfc}`;


      if (searchString) {
        dispatch(inStoreShoppingActions.resetBenefits());
        url += `&search=${searchString}`
      }

      if (sortBy) {
        url += `&ordering=${ordering}${sortBy}`
      }

      if (isFetchMore && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getBenefits(url);
      dispatch(inStoreShoppingActions.setNextPage(data.next));
      dispatch(inStoreShoppingActions.setCount(data.count));
      dispatch(inStoreShoppingActions.setBenefits(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
}

export const addBenefit = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'Content-type': 'multipart/form-data'});
      const { data } = await api.addBenefit(payload);
      await setHeaders({'Content-type': 'application/json'});
      await dispatch(inStoreShoppingActions.resetBenefits());
      await dispatch(inStoreShoppingActions.setCount(0));
      await dispatch(inStoreShoppingActions.setNextPage(0));
      dispatch(getBenefits(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const inStoreShoppingActions: any = inStoreShoppingSlice.actions;

export default inStoreShoppingSlice.reducer;
