import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  loading: boolean;
}

const initialState: LoaderState = {
  loading: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoading: (state: any, action: PayloadAction<boolean, any>): any => {
      state.loading = action.payload;
    },
  },
});

export const loaderActions: any = loaderSlice.actions;
export default loaderSlice.reducer;