import { camelizeKeys, decamelizeKeys } from 'humps';

// Axios middleware to convert all api requests to snake_case
export const snakeCase = ((config: any): any => {
  const newConfig = { ...config };

  // Check if Content-Type multipart/form-data, return original config
  if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
    return newConfig;
  }

  // If config data contains FormData, return original config
  if (newConfig.data instanceof FormData) {
    return newConfig;
  }

  newConfig.url = `${config.url}`;
  if (config.params) newConfig.params = decamelizeKeys(config.params);
  if (config.data) newConfig.data = decamelizeKeys(config.data);

  return newConfig;
});

// Axios middleware to convert all api responses to camelCase
export const camelCase = ((response: any): any => {
  const res = { ...response };
  if (res.data && res.headers['content-type'].includes('application/json')) {
    res.data = camelizeKeys(res.data);
  }
  return res;
});
