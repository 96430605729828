import moment from 'moment';

const isDateValid = (dateStr: string) => {
  // @ts-ignore
  return !isNaN(new Date(dateStr));
}

export const formatDate = (date: any, format: string): string => {
  const isValid: boolean = isDateValid(date);
  if (!isValid) return date;
  return moment(date).format(format);
}