import React, { Fragment, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { modalActions } from '../../store/modal/modal.store';




type ModalSize = 'small' | 'medium' | 'large';
export interface ModalProps {
  visible?: boolean;
  children?: ReactNode;
  closable?: boolean;
  wrapperClassNames?: string;
  bodyClassNames?: string;
  size?: ModalSize;
  style?: any;
  title?: string | ReactNode | undefined;
  headerChildren?: ReactNode | null;
}


const Modal = ({
                 children,
                 visible,
                 closable = true,
                 wrapperClassNames,
                 bodyClassNames,
                 size = 'medium',
                 style,
                 title,
                 headerChildren,
               }: ModalProps) => {


  const dispatch: any = useDispatch();
  const isOpen: boolean = useSelector((state: any) => state.modalStore.isOpen);
  const modalContent: React.JSX.Element = useSelector((state: any) => state.modalStore.modalContent);
  const modalProps: ModalProps = useSelector((state: any) => state.modalStore.modalProps);


  const handleClose = () => {
    // e.stopPropagation();
    dispatch(modalActions.closeModal());
  }


  const calculateSize = (size: string = 'regular'): string => {
    switch (size) {
      case 'small':
        return 'w-11/12 md:w-[520px]';
      case 'regular':
        return 'w-11/12 md:w-[620px]';
      case 'medium':
        return 'w-11/12 md:w-9/12 lg:w-[820px]';
      case 'large':
        return 'w-11/12 md:w-10/12 lg:w-9/12';
      default:
        return 'w-11/12 md:w-6/12';
    }
  }


  return createPortal(
    <Fragment>
      {isOpen &&
          <section
              className="fixed top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center overflow-hidden overflow-y-auto"
              onClick={handleClose}
          >
              <div
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  className={`${calculateSize(modalProps.size)} bg-white rounded-lg max-h-[85%] mx-auto border shadow-xl overflow-y-auto scrollbar scale-in-animation ${modalProps.wrapperClassNames}`}
                  style={style}
              >
                {modalProps.title && (
                  <div className="z-10 h-16 bg-white border-b shadow-inner sticky top-0 text-sm md:text-base flex justify-between items-center px-3 md:px-4 lg:px-6">
                    <h2 className="text-xl font-medium">{modalProps.title}</h2>
                    <button onClick={handleClose} className="text-4xl">&times;</button>
                  </div>
                )}
                  <div className={`relative ${modalProps.bodyClassNames}`}>
                    {!modalProps.title && <button onClick={handleClose} className="absolute right-4 top-1 text-4xl">&times;</button>}
                    {modalContent && modalContent}
                  </div>
              </div>
          </section>
      }
    </Fragment>,
    // @ts-ignore
    document.getElementById('modal')
  )
};

export default Modal;
