
import React, {useEffect, useRef, useState} from 'react';
import useFilters from 'modules/transaction/hooks/useFilters';
import Filters from 'modules/transaction/UI/Filters';
import  { formatDate } from 'libs/datetime';
import { monetaryFormatter } from 'libs/monetary';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
  faTimes,
  faCheck,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {getTransactions, transactionActions} from 'modules/transaction/services/transaction.store';
import InfiniteScroll from 'react-infinite-scroll-component';

const statuses: any = {
  approved: 'Prihvaćeno',
  declined: 'Odbijeno',
  pending: 'Na čekanju',
}

const thClassNames = 'h-12 pl-2 flex items-center';
const tdClassNames = 'h-20 pl-2 flex items-center';


const Transactions = () => {

  const dispatch: any = useDispatch();
  const [expanded, setExpanded] = useState<number | null>(null);
  const user = useSelector((state: any) => state.authStore.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState<number>(0);
  const count: any = useSelector((state: any) => state.transactionStore.count);
  const nextPage: any = useSelector((state: any) => state.transactionStore.nextPage);
  const transactions = useSelector((state: any) => state.transactionStore.transactions);
  const searchString: any = useSelector((state: any) => state.transactionStore.searchString);
  const status: any = useSelector((state: any) => state.transactionStore.status);
  const dateFrom: any = useSelector((state: any) => state.transactionStore.dateFrom);
  const dateTo: any = useSelector((state: any) => state.transactionStore.dateTo);

  const table = useRef(null);
  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === transactions.length;
  }, [count, transactions]);

  const toggleExpanded = (id: number) => {
    if (id === expanded) setExpanded(null);
    else setExpanded(id);
  }
  
  // const filterTransactionsHandler = (): void => {
  //   let filtered = [...transactions];
  //   if (status.value) {
  //     if (status.value === 'finished') filtered = filtered.filter((t: any) => t.finished  && !t.canceled);
  //     if (status.value === 'cancelled') filtered = filtered.filter((t: any) => t.canceled && t.finished);
  //     if (status.value === 'in_progress') filtered = filtered.filter((t: any) => !t.canceled && !t.finished);
  //     setFilteredTransactions(filtered);
  //   }
  //
  //   if (startDate) {
  //     filtered = filtered.filter((r: any) => new Date(r.createdAt) >= new Date(startDate));
  //     setFilteredTransactions(filtered);
  //   }
  //
  //   if (endDate) {
  //     filtered = filtered.filter((r: any) => new Date(r.createdAt) <= new Date(endDate));
  //     setFilteredTransactions(filtered);
  //   }
  //
  //
  //   if (searchString) {
  //     const search = searchString.toLowerCase();
  //     filtered = filtered.map((r: any) => {
  //       return {
  //         ...r,
  //         fullName: r.firstName + ' ' + r.lastName,
  //       }
  //     });
  //     filtered = filtered.filter((r: any) => r.fullName.toLowerCase().includes(search));
  //     setFilteredTransactions(filtered)
  //   }
  //   setFilteredTransactions(filtered)
  // }

  useEffect(() => {
    return () => {
      dispatch(transactionActions.setNextPage(null));
      dispatch(transactionActions.setCount(0));
      dispatch(transactionActions.resetTransactions());
    }
  }, []);

  const fetchMoreTransactions = async (): Promise<void> => {
    try {
      await dispatch(getTransactions());
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  useEffect(() => {
    fetchMoreTransactions();
  }, [searchString, status, dateFrom, dateTo]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreTransactions();
      }
    };

    // @ts-ignore
    window.addEventListener('scroll', handleScroll);
    return () => {
      // @ts-ignore
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const searchStringHandler = (payload: string) => {
    dispatch(transactionActions.setSearchString(payload));
  };

  const dateFromHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(transactionActions.setDateFrom(date));
  };

  const dateToHandler = (payload: string) => {
    const date: string = formatDate(payload, 'YYYY-MM-DD');
    dispatch(transactionActions.setDateTo(date));
  };

  const statusHandler = (payload: string) => {
    dispatch(transactionActions.setStatus(payload));
  };

  const getTableHeight = () => {
    let height: number =  document.documentElement.clientHeight - 305;
    console.log(height, 'height')
    setTableHeight(height);
  }

  useEffect(() => {
    getTableHeight();
  }, [transactions]);




  return (
    <>
      <Filters
        onExport={() => null}
        onSearch={(str: string) => searchStringHandler(str)}
        onStatusChange={(status: any) => statusHandler(status.value)}
        onEndDateChange={(date: any) => dateToHandler(date)}
        onStartDateChange={(date: any) => dateFromHandler(date)}
      />

      <section
        id="table"
        className={`${loading && 'min-h-[70vh]'} relative w-full max-h-[calc(100vh-255px)] mt-7 overflow-y-auto rounded-b-lg overflow-hidden border`}>
        <div
          className="sticky top-0 z-10 w-full h-12 rounded-t-lg bg-base-600 text-white text-sm font-bold flex divide-x-2 divide-white items-center">
          <div className={`w-1/12 ${thClassNames}`}>ID</div>
          <div className={`w-2/12 ${thClassNames}`}>Datum</div>
          <div className={`w-2/12 ${thClassNames}`}>Korisnik</div>
          <div className={`w-3/12 ${thClassNames}`}>Benefit provajder</div>
          <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Prilog</div>
          <div className={`w-2/12 ${thClassNames}`}>Ukupna cena</div>
          <div className={`w-1/12 ${thClassNames} justify-center !pl-0`}>Status</div>
        </div>
        {tableHeight > 0 && <InfiniteScroll
          dataLength={transactions.length}
          next={fetchMoreTransactions}
          height={tableHeight}
          hasMore={count !== transactions.length}
          loader={""}
        >
          {transactions.length > 0 && transactions.map((t: any, index: number) =>
            <div key={index}
                 className={`${t.id === expanded && 'border border-base-600 border-box transition duration-500 ease-in-out'} hover:bg-gray-50`}>
              <div onClick={() => toggleExpanded(t.id)}
                   className="w-full h-20 bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center cursor-pointer">
                <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}>{t.id}</div>
                <div className={`w-2/12 ${tdClassNames}`}>{formatDate(t.createdAt, 'DD.MM.YYYY.')}</div>
                <div className={`w-2/12 ${tdClassNames}`}>{t.firstName} {t.lastName}</div>
                <div className={`w-3/12 h-20 pl-2 flex items-center`}>
                  <div>
                    {t.benefitsString && t.benefitsString.length > 0 && t.benefitsString.map((b: any) =>
                      <p key={b.providerName}>{b.providerName}</p>
                    )}
                  </div>
                </div>
                <div className={`w-1/12 ${tdClassNames} justify-center !pl-0`}>
                  <Tooltip id="download" className="z-10"/>
                  <a onClick={(e: any) => e.stopPropagation()} href={t.invoice[0]?.pdf} target="_blank">
                    <FontAwesomeIcon
                      data-tooltip-id="download"
                      data-tooltip-content="Klikni da preuzmeš  "
                      icon={faFile}
                      className="hover:text-base-500 text-2xl animation duration-200 ease-in-out"
                    />
                  </a>
                </div>
                <div className={`w-2/12 ${tdClassNames} font-medium`}>{monetaryFormatter(t.price)}</div>
                <div className={`w-1/12 flex items-center justify-center ${tdClassNames} !pl-0`}>
                  <Tooltip id="status" className="z-10"/>
                  {!t.finished &&
                      <span
                          data-tooltip-id="status"
                          data-tooltip-content="U procesu"
                          className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-yellow-500`}>
                    <FontAwesomeIcon icon={faHourglassHalf}/>
                  </span>
                  }
                  {t.finished && t.canceled &&
                      <span
                          data-tooltip-id="status"
                          data-tooltip-content="Stornirano"
                          className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-red-600`}>
                    <FontAwesomeIcon icon={faTimes}/>
                  </span>
                  }

                  {t.finished && !t.canceled &&
                      <span
                          data-tooltip-id="status"
                          data-tooltip-content="Završeno"
                          className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-green-600`}>
                    <FontAwesomeIcon icon={faCheck}/>
                  </span>
                  }
                </div>
              </div>
              <div>
                {t.benefitsString && t.benefitsString.length > 0 && t.benefitsString.map((b: any) =>
                  <div
                    key={b}
                    className={`collapsible-content ${t.id === expanded && 'open'} w-full bg-primary-50 ${t.id === expanded && 'border-t-2'} border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center`}>
                    <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}>
                      <div className="space-y-1">
                        <p className="font-medium">User ID:</p>
                        <p className="text-base-600 font-medium">{t.user.id ? t.user.id : '/'}</p>
                      </div>
                    </div>
                    <div className={`w-2/12 ${tdClassNames}`}>
                      <div className="space-y-1">
                        <p className="font-medium">Vreme</p>
                        <p className="text-base-600 font-medium">{formatDate(t.createdAt, 'HH:mm')}</p>
                      </div>
                    </div>
                    <div className={`w-2/12 ${tdClassNames}`}>
                      <div className="space-y-1">
                        <p className="font-medium">Mejl</p>
                        <p className="text-base-600 font-medium">{t.email ? t.email : '/'}</p>
                      </div>
                    </div>
                    <div className={`w-3/12 ${tdClassNames}`}>
                      <div className="space-y-1">
                        <p className="font-medium">Naziv benefita</p>
                        <p className="text-center text-base-600 font-medium">{b.name}</p>
                      </div>
                    </div>
                    <div className={`w-1/12 ${tdClassNames} flex items-center justify-center !pl-0`}>
                      <div className="space-y-1">
                        <p className="font-medium">Količina</p>
                        <p className="text-center text-base-600 font-medium">{b.count}</p>
                      </div>
                    </div>
                    <div className={`w-2/12 ${tdClassNames}`}>
                      <div className="space-y-1">
                        <p className="font-medium">Cena</p>
                        <p className="text-center text-base-600 font-medium">{monetaryFormatter(b.price)}</p>
                      </div>
                    </div>
                    <div className={`w-1/12 flex items-center justify-center ${tdClassNames}`}></div>
                    {/*<div className={`w-2/12 ${tdClassNames}`}>Ukupna cena</div>*/}
                    {/*<div className={`w-1/12 ${tdClassNames}`}>Status</div>*/}
                  </div>
                )}
              </div>
            </div>
          )}
        </InfiniteScroll>}
        {/*{transactions.length > 0 && transactions.map((t: any) =>*/}
        {/*  <div key={t.id}*/}
        {/*       className={`${t.id === expanded && 'border border-base-600 border-box transition duration-500 ease-in-out'} hover:bg-gray-50`}>*/}
        {/*    <div onClick={() => toggleExpanded(t.id)}*/}
        {/*         className="w-full h-20 bg-primary-50 border-t-2 border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center cursor-pointer">*/}
        {/*      <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}>{t.id}</div>*/}
        {/*      <div className={`w-2/12 ${tdClassNames}`}>{formatDate(t.createdAt, 'DD.MM.YYYY.')}</div>*/}
        {/*      <div className={`w-2/12 ${tdClassNames}`}>{t.firstName} {t.lastName}</div>*/}
        {/*      <div className={`w-3/12 h-20 pl-2 flex items-center`}>*/}
        {/*        <div>*/}
        {/*          {t.benefitsString && t.benefitsString.length > 0 && t.benefitsString.map((b: any) =>*/}
        {/*            <p key={b.providerName}>{b.providerName}</p>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className={`w-1/12 ${tdClassNames} justify-center !pl-0`}>*/}
        {/*        <Tooltip id="download" className="z-10"/>*/}
        {/*        <a onClick={(e: any) => e.stopPropagation()} href={t.invoice[0]?.pdf} target="_blank">*/}
        {/*          <FontAwesomeIcon*/}
        {/*            data-tooltip-id="download"*/}
        {/*            data-tooltip-content="Klikni da preuzmeš  "*/}
        {/*            icon={faFile}*/}
        {/*            className="hover:text-base-500 text-2xl animation duration-200 ease-in-out"*/}
        {/*          />*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className={`w-2/12 ${tdClassNames} font-medium`}>{monetaryFormatter(t.price)}</div>*/}
        {/*      <div className={`w-1/12 flex items-center justify-center ${tdClassNames} !pl-0`}>*/}
        {/*        <Tooltip id="status" className="z-10"/>*/}
        {/*        {!t.finished &&*/}
        {/*            <span*/}
        {/*                data-tooltip-id="status"*/}
        {/*                data-tooltip-content="U procesu"*/}
        {/*                className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-yellow-500`}>*/}
        {/*            <FontAwesomeIcon icon={faHourglassHalf}/>*/}
        {/*          </span>*/}
        {/*        }*/}
        {/*        {t.finished && t.canceled &&*/}
        {/*            <span*/}
        {/*                data-tooltip-id="status"*/}
        {/*                data-tooltip-content="Stornirano"*/}
        {/*                className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-red-600`}>*/}
        {/*            <FontAwesomeIcon icon={faTimes}/>*/}
        {/*          </span>*/}
        {/*        }*/}

        {/*        {t.finished && !t.canceled &&*/}
        {/*            <span*/}
        {/*                data-tooltip-id="status"*/}
        {/*                data-tooltip-content="Završeno"*/}
        {/*                className={`flex items-center justify-center rounded-full h-8 w-8 text-white text-xl bg-green-600`}>*/}
        {/*            <FontAwesomeIcon icon={faCheck}/>*/}
        {/*          </span>*/}
        {/*        }*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      {t.benefitsString && t.benefitsString.length > 0 && t.benefitsString.map((b: any) =>*/}
        {/*        <div*/}
        {/*          className={`collapsible-content ${t.id === expanded && 'open'} w-full bg-primary-50 ${t.id === expanded && 'border-t-2'} border-white text-gray-700 text-sm flex divide-x-2 divide-white items-center`}>*/}
        {/*          <div className={`w-1/12 font-medium ${tdClassNames} flex items-center justify-between`}></div>*/}
        {/*          <div className={`w-2/12 ${tdClassNames}`}>*/}
        {/*            <div className="space-y-1">*/}
        {/*              <p><span className="font-medium">Kompanijski ID:</span> {user.company.id}</p>*/}
        {/*              <p><span className="font-medium">User ID:</span> {t.hobId}</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={`w-2/12 ${tdClassNames}`}>*/}
        {/*            <div className="space-y-1">*/}
        {/*              <p className="font-medium">Mejl</p>*/}
        {/*              <p className="text-base-600 font-medium">{t.email ? t.email : '/' }</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={`w-3/12 ${tdClassNames}`}>*/}
        {/*            <div className="space-y-1">*/}
        {/*              <p className="font-medium">Naziv benefita</p>*/}
        {/*              <p className="text-center text-base-600 font-medium">{b.name}</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={`w-1/12 ${tdClassNames} flex items-center justify-center !pl-0`}>*/}
        {/*            <div className="space-y-1">*/}
        {/*              <p className="font-medium">Količina</p>*/}
        {/*              <p className="text-center text-base-600 font-medium">{b.count}</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={`w-2/12 ${tdClassNames}`}>*/}
        {/*            <div className="space-y-1">*/}
        {/*              <p className="font-medium">Cena</p>*/}
        {/*              <p className="text-center text-base-600 font-medium">{monetaryFormatter(b.price)}</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={`w-1/12 flex items-center justify-center ${tdClassNames}`}></div>*/}
        {/*          /!*<div className={`w-2/12 ${tdClassNames}`}>Ukupna cena</div>*!/*/}
        {/*          /!*<div className={`w-1/12 ${tdClassNames}`}>Status</div>*!/*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {!loading && transactions.length === 0 &&
            <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">Nema pronađenih
                kupovina.</div>}
      </section>
    </>
  );
};

export default Transactions;