import http from 'libs/http';

class CartApi {
  requestCode = (payload: any) => {
    return http.post('/v2/admin/request-code/', payload);
  };

  makeProviderPayment = (payload: any) => {
    return http.post('/v2/admin/make-provider-payment/', payload);
  };
}


export default CartApi;