import http from 'libs/http';


class TransactionApi {
  getTransactions = (url: string) => {
    return http.get(url);
  };
}


export default TransactionApi;