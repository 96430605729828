import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DatepickerProps {
  placeholder?: string;
  value?: any;
  label?: string;
  format?: string;
  classNames?: string;
  onChange: (date: any) => void;
}

const Datepicker = ({ value, label, classNames, format, placeholder, onChange }: DatepickerProps) => {
  const [date, setDate] = useState<any>('');

  const onChangeHandler = (date: string) => {
    setDate(date);
    onChange(date);
  }

  useEffect(() => {
    if (value) setDate(value);
  }, [value]);

  return (
    <div className="w-full">
      {label &&
          <label className="block text-gray-700 text-sm font-bold mb-1">
            { label }
          </label>
      }
      <DatePicker
        selected={date}
        dateFormat={format}
        showIcon
        icon={
          <svg className="z-30 mt-[3px] transform -translate-x-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="16" width="16" role="img" aria-hidden="true" data-icon="calendarAlt">
            <path fill="#d1d5db" d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"></path>
          </svg>
        }
        isClearable={value}
        placeholderText={placeholder}
        onSelect={(value: any) => onChangeHandler(value)}
        onChange={(value: any) => onChangeHandler(value)}
        className={`w-full pl-12 h-10 z-20 relative rounded-lg border hover:border-base-500 focus:ring-2 focus:ring-base-500 focus:outline-0 animation duration-200 ease-in-out ${classNames}`}
      />
    </div>
  )
}

export default Datepicker;