export const generateRandomId = (length: number): string => {
  const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId: string = '';


  for (let i: number = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }


  return randomId;
}


export const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export const currencyFormatter = (number: number) => {
  const amount = Number(number);
  let numStr = amount.toFixed(2);

  numStr = numStr.toString();

  let parts = numStr.split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
}
