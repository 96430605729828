import { createSlice, Slice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';


interface SidebarState {
  isOpen: boolean;
}


const initialState: SidebarState = {
  isOpen: false,
};


const sidebarSlice: Slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    toggleSidebar: (state: any): void => {
      state.isOpen = !state.isOpen;
    },
  },
});




export const sidebarActions: any = sidebarSlice.actions;
export default sidebarSlice.reducer;
