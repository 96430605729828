import React, { useState } from 'react';
import SendHobIdForm from 'modules/cart/UI/SendHobIdForm';
import SendVerificationCodeForm from 'modules/cart/UI/SendVerificationCode';


const BillingRequestForm = () => {

  const [step, setStep] = useState<number>(0);
  const [id, setId] = useState<any>(null);

  const onFirstFormSuccess = async (id: number) => {
    await setId(id);
    setStep(1);
  }

  return (
    <>
      {step === 0 && <SendHobIdForm onSuccess={(id: number) => onFirstFormSuccess(id)} />}
      {step === 1 && <SendVerificationCodeForm id={id} />}
    </>
  )
}


export default BillingRequestForm;