import React, { useRef, useState } from 'react';
import http, {setHeaders} from 'libs/http';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import { addBenefit } from 'modules/in-store-shopping/services/inStoreShopping.store';

const AddBenefitForm = () => {

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      price: '',
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    if (isValid) {
      let payload: any = {};

      payload.name = data.name;
      payload.price = data.price;
      payload.nfc = true;
      payload.for_provider = true;
      payload.provider = user.company.id;
      payload.category = user.company.category;

      const formData: FormData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }

      try {
        await dispatch(addBenefit(formData));
        dispatch(modalActions.closeModal());
        toast.success('Uspešno ste dodali in-store-shopping!');
      } catch (e: any) {
        toast.error('Nešto nije u redu!');
      }
    }
  };

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <div className="relative">
      <div className="p-7 space-y-7">
        <div>
          <Input
            type='text'
            label="Naziv"
            autoFocus
            height='medium'
            placeholder="Unesi naziv"
            isTouched={touchedFields.name}
            error={errors.name}
            validationRules={register('name', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Cena (sa popustom)"
            height='medium'
            placeholder="Unesi cenu sa popustom"
            isTouched={touchedFields.price}
            error={errors.price}
            validationRules={register('price', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
              min: {
                value: 1,
                message: 'Iznos cene ne može biti 0'
              },
            })}
            onChange={() => null}
          />
        </div>
      </div>
      <div
        className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Dodaj
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddBenefitForm;