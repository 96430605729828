import React, { useEffect, useState, useRef } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';
import Cart from 'modules/cart/UI/Cart';
import CheckBox from 'components/Checkbox';
import ListView from 'modules/in-store-shopping/UI/ListView';
import GridView from 'modules/in-store-shopping/UI/GridView';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from 'modules/cart/services/cart.store';
import AddBenefitForm from 'modules/in-store-shopping/UI/AddBenefitForm';
import SearchableDropdown from 'components/SearchableDropdown';
import {
  inStoreShoppingActions,
  getBenefits,
} from 'modules/in-store-shopping/services/inStoreShopping.store';
import {
  faPlus,
  faUpLong,
  faDownLong,
  faGrip,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {orderBy} from "lodash";


const sortOptions = [
  { label: 'Popularnosti', value: 'bought'},
  { label: 'Datumu', value: 'added'},
  { label: 'Nazivu', value: 'name'},
  { label: 'Ceni', value: 'price'},
]



const InStoreShopping = () => {

  const dispatch: any = useDispatch();

  const count: any = useSelector((state: any) => state.inStoreShoppingStore.count);
  const cart: any = useSelector((state: any) => state.cartStore.cart);
  const isNfc: boolean = useSelector((state: any) => state.inStoreShoppingStore.isNfc);
  const benefits: any = useSelector((state: any) => state.inStoreShoppingStore.benefits);
  const sortBy: any = useSelector((state: any) => state.inStoreShoppingStore.sortBy);
  const ordering: any = useSelector((state: any) => state.inStoreShoppingStore.ordering);
  const nextPage: any = useSelector((state: any) => state.inStoreShoppingStore.nextPage);
  const globalLoading: any = useSelector((state: any) => state.loaderStore.loading);
  const searchString: any = useSelector((state: any) => state.inStoreShoppingStore.searchString);
  const [sortByHelper, setSortByHelper] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [previewType, setPreviewType] = useState<string>('grid');

  const setSearchString = (payload: string): void => {
    dispatch(inStoreShoppingActions.setSearchString(payload));
  };

  const setSortByHandler = async (payload: string | null): Promise<any> => {
    dispatch(inStoreShoppingActions.resetBenefits());
    await dispatch(inStoreShoppingActions.setCount(0));
    await dispatch(inStoreShoppingActions.setNextPage(null));
    if (payload) {
      dispatch(inStoreShoppingActions.setSort(payload));
    } else {
      dispatch(inStoreShoppingActions.setSort(''));
    }
  };

    const openAddBenefitForm = (): void => {
    let modalContent: React.JSX.Element = (
      <AddBenefitForm />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj in-store-shopping',
          size: 'small',
        },
      })
    );
  };

  const fetchMoreBenefits = async (isFetchMore: boolean): Promise<void> => {
    try {
      setLoading(true);
      await dispatch(getBenefits(isFetchMore));
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e, 'Err');
    }
  };

  useEffect(() => {
    dispatch(inStoreShoppingActions.setCount(0));
    dispatch(inStoreShoppingActions.setNextPage(null));
    dispatch(inStoreShoppingActions.resetBenefits());
  }, []);

  useEffect((): void => {
    fetchMoreBenefits(false);
  }, [searchString, sortBy, isNfc, ordering]);

  const nextPageRef = useRef(nextPage);
  const countRef = useRef(count);

  useEffect((): void => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === benefits.length;
  }, [count, benefits]);

  useEffect(() => {
    const handleScroll = (): void => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreBenefits(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    transformSortValue();
  }, [sortBy]);

  const transformSortValue = () => {
    const target = sortOptions.find((o: any) => o.value === sortBy);
    if (target) setSortByHelper(target);
    else setSortByHelper(null);
  }

  const toggleNfc = async (): Promise<void> => {
    await dispatch(inStoreShoppingActions.setCount(0));
    await dispatch(inStoreShoppingActions.setNextPage(null));
    await dispatch(inStoreShoppingActions.resetBenefits());
    dispatch(inStoreShoppingActions.setIsNfc(!isNfc));
  }

  const addToCartHandler = (benefit: any): void => {
    dispatch(cartActions.addToCart(benefit));
  };

  const removeFromCartHandler = (benefitId: any): void => {
    dispatch(cartActions.removeFromCart(benefitId));
  };

  const setOrderingHandler = async (ordering: string) => {
    await dispatch(inStoreShoppingActions.resetBenefits());
    dispatch(inStoreShoppingActions.setOrdering(ordering))
  }


  return (
    <>
      <Cart />
      <section className={`flex items-center justify-between ${cart.length > 0 ? 'mt-5' : 'mt-0'}`}>
        <div>
          <Button
            bg="primary"
            className="lg:w-80 flex items-center justify-center gap-x-2"
            size="regular"
            onClick={openAddBenefitForm}
          >
            <FontAwesomeIcon icon={faPlus}/>
            <p>Kreiraj benefit za naplatu</p>
          </Button>
        </div>
      </section>
      <section className="mt-6 flex items-center justify-between">
        <div className="flex items-center gap-x-3">
          <div className="lg:w-[322px]">
            <Input
              type='text'
              isSearch
              height='regular'
              debounceTime={400}
              placeholder=" Pretraži benefite po nazivu"
              onChange={setSearchString}
            />
          </div>
          <div className="w-1/2">
            <CheckBox
              title="Prikaži samo benefite na HoB platformi"
              size="regular"
              value={!isNfc}
              textClassNames="font-medium"
              onChange={toggleNfc}
            />
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="w-48">
            <SearchableDropdown
              value={sortByHelper}
              idField="value"
              valueToShow="label"
              isClearable
              placeholder="Sortiraj po"
              options={sortOptions}
              onChange={(order: any) => order ? setSortByHandler(order.value) : setSortByHandler('')}
            />
          </div>
          <div className="flex items-center justify-center gap-x-2">
            <div
              className="relative w-full z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
              <button
                onClick={() => setOrderingHandler('-')}
                type="button"
                className={`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faDownLong}/>
              </button>
              <button
                onClick={() => setOrderingHandler('')}
                type="button"
                className={`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${ordering !== '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faUpLong}/>
              </button>
            </div>
            <div
              className="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
              <button
                onClick={() => setPreviewType('grid')}
                type="button"
                className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-l-md border border-gray-300 ${previewType === 'grid' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faGrip}/>
              </button>
              <button
                onClick={() => setPreviewType('list')}
                type="button"
                className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-r-md border border-gray-300 ${previewType === 'list' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
                <FontAwesomeIcon icon={faListUl}/>
              </button>
            </div>
          </div>
        </div>
      </section>

      {previewType === 'grid' && benefits.length > 0 &&
        <GridView
          benefits={benefits}
          onAddToCart={(benefit: any) => addToCartHandler(benefit)}
          onRemoveFromCart={(benefitId: number) => removeFromCartHandler(benefitId)}
        />
      }
      {previewType === 'list' && benefits.length > 0 &&
        <ListView
          benefits={benefits}
          onAddToCart={(benefit: any) => addToCartHandler(benefit)}
          onRemoveFromCart={(benefitId: number) => removeFromCartHandler(benefitId)}
        />
      }

      {loading && !globalLoading &&
        <div className="relative h-[35vh] lg:h-[55vh] rounded-lg my-9 flex items-center justify-center text-gray-700">
            <ElementLoader color="primary" size="regular" classNames="flex items-center justify-center"/>
        </div>
      }

      {!loading && benefits.length === 0 &&
        <div className="h-[35vh] lg:h-[55vh] rounded-lg my-9 font-medium flex items-center justify-center text-gray-700">
          Nema podataka.
        </div>
      }
    </>
  );
};

export default InStoreShopping;