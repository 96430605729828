import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import CartApi from 'modules/cart/services/cart.api';

const api: CartApi = new CartApi();

interface CartState {
  cart: any;
}

const initialState: CartState = {
  cart: [],
};

const cartSlice: any = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state: any, action: PayloadAction<any, any>): any => {
      const alreadyInCart = state.cart.find((benefit: any) => benefit.id === action.payload.id);
      if (alreadyInCart) return;
      const cart: any[] = [...state.cart, action.payload];
      state.cart = cart;
    },
    removeFromCart: (state: any, action: PayloadAction<any, any>): any => {
      const cart: any[] = [...state.cart];
      state.cart = cart.filter((benefit: any) => benefit.id !== action.payload);
    },
    setCount: (state: any,  action: PayloadAction<any, any>): any => {
      const cart: any[] = [...state.cart];
      const index: number =  cart.findIndex((benefit: any) => benefit.id === action.payload.benefitId);
      console.log(action.payload, 'payload action')
      cart[index] = { ...cart[index], count: action.payload.count };
      state.cart = cart;
    },
    resetCart: (state: any, action: PayloadAction<any, any>): any => {
      state.cart = [];
    },
  },
});

export const requestCode = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.requestCode(payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};

export const makeProviderPayment = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.makeProviderPayment(payload);
      dispatch(cartActions.resetCart());
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
};


export const cartActions: any = cartSlice.actions;


export default cartSlice.reducer;
