import React, { useState } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ElementLoader } from 'components/Loaders';
import {modalActions} from 'store/modal/modal.store';
import { useDispatch, useSelector } from "react-redux";
import { makeProviderPayment } from 'modules/cart/services/cart.store';

const SendVerificationCodeForm = ({ id, onSuccess }: any) => {


  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      code: '',
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const cart: any = useSelector((state: any) => state.cartStore.cart);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    if (isValid) {
      let payload: any = {
        id: +id,
        code: data.code,
        cart: cart,
      };

      try {
        const data = await dispatch(makeProviderPayment(payload));
        if (data.codeProblem) {
          toast.error('Kod nije ispravan!');
          return;
        }
        if (data.codeProblem) {
          toast.error('Kod nije ispravan!');
          return;
        }
        toast.success('Uspešna kupovina i naplata!');
        dispatch(modalActions.closeModal());
      } catch (e: any) {
        if (e.status === 417) {
          toast.error('Korisnik nema dovoljno benefit budžeta za ovu kupovinu!');
        } else if (e.response.status === 500) {
          toast.error('Nešto nije u redu!')
        } else {
          toast.error(e.response.data);
        }
      }
    }
  };

  const closeForm = (): void => {
    dispatch(modalActions.closeModal());
  };

  return(
    <>
      <div className="relative">
        <div className="p-7 space-y-7">
          <div>
            <Input
              type='text'
              label="Verifikacioni kod dobijen od korisnika"
              autoFocus
              height='medium'
              placeholder="Unesi verifikacioni kod dobijen od korisnika"
              isTouched={touchedFields.code}
              error={errors.code}
              validationRules={register('code', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
          <Button
            bg="white"
            className="w-full flex items-center justify-center gap-x-2"
            size="regular"
            onClick={closeForm}
          >
            Otkaži
          </Button>
          <Button
            bg="primary"
            className="w-full gap-x-2 relative"
            size="regular"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Izvrši transakciju
            {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
          </Button>
        </div>
      </div>
    </>
  );
}

export default SendVerificationCodeForm;