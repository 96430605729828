import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModalProps } from "../../components/Modal";
import React from 'react';




interface ModalState {
  isOpen: boolean;
  modalProps: ModalProps | null;
  modalContent: React.JSX.Element | null;
  confirmDenyPrompt: boolean;
  confirmDenyProps: any;
}


const initialState: ModalState = {
  isOpen: false,
  modalProps: null,
  modalContent:  null,
  confirmDenyPrompt: false,
  confirmDenyProps: null,
};


const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state: any, action: PayloadAction<ModalState, any>): any => {
      state.isOpen = true;
      state.modalProps = action.payload.modalProps;
      state.modalContent = action.payload.modalContent;
    },
    closeModal: (state: any): any => {
      state.isOpen = false;
      state.modalProps = null;
      state.modalContent = null;
    },
  },
});


export const modalActions: any = modalSlice.actions;
export default modalSlice.reducer;
